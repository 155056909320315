import React, { useState, useEffect } from 'react';
import './styles.scss';
import TextButton from '../TextButton';

import numeral from 'numeral';
import axios from 'axios';

export default function FloatingBoard({ selectedCAR, isCAR, body, onChange, onSidebar, onBody, generalInfo }) {
  const [selectedCarData, _selectedCarData] = useState(null);
  const [selectedCARPastagem, _selectedCARPastagem] = useState(null);

  useEffect(() => {
    _selectedCARPastagem(null);
    _selectedCarData(null);

    (async () => {
      if(!selectedCAR) return;

      let { data } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}`);
      _selectedCarData(data);

      let {
        data: { area },
      } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}/pastagem`);
      _selectedCARPastagem(area);
    })();
  }, [selectedCAR]);

  if (isCAR) return <CARFloatingBoard selectedCarData={selectedCarData} selectedCARPastagem={selectedCARPastagem} />;

  if (selectedCarData) return <PreCARFloatingBoard selectedCarData={selectedCarData} selectedCARPastagem={selectedCARPastagem} onBody={onBody} />;

  return <DefaultFloatingBoard body={body} onChange={onChange} onSidebar={onSidebar} generalInfo={generalInfo} />;
}

function DefaultFloatingBoard({ body, onChange, onSidebar, generalInfo }) {
  return (
    <>
      <div className="floating-board">
        <div className="nav-switch">
          <input type="checkbox" id="switch" checked={body === 'no_map'} onChange={(e)=>console.log(e.target.checked)} />
          <label htmlFor="switch">
            <span onClick={()=>onChange('map')}>MAPA</span>
            <span onClick={()=>{ onChange('no_map');onSidebar('filters') }}>PAINEL</span>
          </label>
        </div>
        <div className="separator"></div>
        {body === 'no_map' && <div className="text-if-panel">Texto sobre o painel abaixo...</div>}
        {body !== 'no_map'&& <div className="numbers">
          <div className={`item ${generalInfo && generalInfo.quantidade ? '' : 'loading'}`}>
            <div className="number">{generalInfo && generalInfo.quantidade ? numeral(generalInfo.quantidade).format('0,0') : '-'}</div>
            <div className="title">imóveis</div>
          </div>
          <div className={`item ${generalInfo && generalInfo.area ? '' : 'loading'}`}>
            <div className="number">{generalInfo && generalInfo.area ? numeral(generalInfo.area).format('0,0.00') : '-'}</div>
            <div className="title">hectares</div>
          </div>

          <div className="item">
            <div className="number">-</div>
            <div className="title">ha desmatados</div>
          </div>

          <div className={`item ${generalInfo && generalInfo.municipios ? '' : 'loading'}`}>
            <div className="number">{generalInfo && generalInfo.municipios ? numeral(generalInfo.municipios).format('0,0') : '-'}</div>
            <div className="title">municipios</div>
          </div>

          <div className={`item ${generalInfo && generalInfo.estados ? '' : 'loading'}`}>
            <div className="number">{generalInfo && generalInfo.estados ? numeral(generalInfo.estados).format('0,0') : '-'}</div>
            <div className="title">estados</div>
          </div>

          <div className="item">
            <div className="number">1</div>
            <div className="title">biomas</div>
          </div>

          <div className="item">
            <div className="number">{generalInfo && generalInfo.emb ? numeral(generalInfo.emb).format('0,0') : '-'}</div>
            <div className="title">areas embargadas</div>
          </div>

          <div className="item">
            <div className="number">{generalInfo && generalInfo.tis ? numeral(generalInfo.tis).format('0,0') : '-'}</div>
            <div className="title">sobreposições com TI</div>
          </div>

          <div className="item">
            <div className="number">{generalInfo && generalInfo.ucs ? numeral(generalInfo.ucs).format('0,0') : '-'}</div>
            <div className="title">sobreposições com UC</div>
          </div>
        </div>}
      </div>
    </>
  );
}

function PreCARFloatingBoard({ selectedCarData, selectedCARPastagem, onBody }) {
  return (
    <>
      <div className="floating-board">
        <div className="car">
          <div className="icon">
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="css-i6dzq1"
            >
              <line x1="18" y1="20" x2="18" y2="10"></line>
              <line x1="12" y1="20" x2="12" y2="4"></line>
              <line x1="6" y1="20" x2="6" y2="14"></line>
            </svg>
          </div>
          <div className="title">{selectedCarData.cod_imovel}</div>
        </div>

        <div className="numbers">
          <div className="item">
            <div className="number">{numeral(parseFloat(selectedCarData.area)).format('0,0.00')}</div>
            <div className="title">área (ha)</div>
          </div>
          <div className="item">
            <div className="number">{numeral(parseFloat(selectedCarData.m_fiscal)).format('0,0.000')}</div>
            <div className="title">módulos</div>
          </div>

          <div className="item">
            <div className="number">{selectedCARPastagem ? numeral(parseFloat(selectedCARPastagem)).format('0,0.00') : '-'}</div>
            <div className="title">pastagem (ha)</div>
          </div>
        </div>
        <div className="right-buttons">
          <TextButton onClick={() => onBody('no_map')} />
        </div>
      </div>
    </>
  );
}

function CARFloatingBoard({ selectedCarData, selectedCARPastagem }) {
  return (
    <>
      <div className="floating-board">
        <div className="car">
          <div className="icon">
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="css-i6dzq1"
            >
              <line x1="18" y1="20" x2="18" y2="10"></line>
              <line x1="12" y1="20" x2="12" y2="4"></line>
              <line x1="6" y1="20" x2="6" y2="14"></line>
            </svg>
          </div>
          <div className="title">{selectedCarData ? selectedCarData.cod_imovel : 'Carregando...'}</div>
        </div>
        {selectedCarData && (
          <>
            <div className="numbers">
              <div className="item">
                <div className="title">Protocolo</div>
                <div className={!selectedCarData ? '' : selectedCarData.protocolo}>{selectedCarData.protocolo}</div>
              </div>
            </div>
            <div className="right-buttons">
              <TextButton type="compartilhar" onClick={() => console.log('todo')} />
              <div></div>
              <TextButton type="baixar" onClick={() => console.log('todo')} />
            </div>
          </>
        )}
      </div>
    </>
  );
}
