import React from 'react';
import './styles.scss';

export default function TextButton({ type = 'ver_relatorio', onClick }) {
  return (
    <>
      <div className="text-button" onClick={onClick}>
        {type === 'ver_relatorio' && (
          <>
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="css-i6dzq1"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            VER RELATÓRIO
          </>
        )}

        {type === 'compartilhar' && (
          <>
            <svg
            viewBox="0 0 24 24" 
            width="24" height="24" 
            stroke="currentColor" 
            strokeWidth="2" fill="none" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className="css-i6dzq1">
              <circle cx="18" cy="5" r="3"></circle>
              <circle cx="6" cy="12" r="3"></circle>
              <circle cx="18" cy="19" r="3"></circle>
              <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
              <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
            </svg>
            COMPARTILHAR
          </>
        )}

        {type === 'baixar' && (
          <>
            <svg 
            viewBox="0 0 24 24" 
            width="24" height="24" 
            stroke="currentColor" 
            strokeWidth="2" 
            fill="none" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className="css-i6dzq1">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="7 10 12 15 17 10"></polyline>
              <line x1="12" y1="15" x2="12" y2="3"></line>
            </svg>
            BAIXAR
          </>
        )}
      </div>
    </>
  );
}
