import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, FormControlLabel, FormGroup, Typography, unstable_useId } from '@mui/material';
import './styles.scss';
import { AutocompleteStyled, AutocompleteAsyncStyled } from '../AutocompleteStyled';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import BnlSlider from '../BnlSlider';

import { startFilters } from '../../utils';

import consulta from '../../images/consulta.png';

export default function SideBar({ cars, onSearch, onChange, body, sidebar, onSidebar, selectedCAR, onRemove }) {
  return (
    <div className={`sidebar ${sidebar !== 'empty' ? 'show' : ''} ${sidebar === 'search' ? 'search' : ''} ${body === 'no_map' ? 'show' : ''}`}>
      {sidebar === 'filters' && <SideBarFilters sidebar={sidebar} onSidebar={onSidebar} onChange={onChange} body={body} />}
      {sidebar === 'search' && (
        <SideBarSearch
          onChange={onChange}
          onSidebar={onSidebar}
          selectedCAR={selectedCAR}
          body={body}
          cars={cars}
          onSearch={onSearch}
          onRemove={onRemove}
        />
      )}
    </div>
  );
}

let setup = true;
function SideBarFilters({ onChange, onSidebar, body }) {
  const randomID = unstable_useId();
  const [filters, _filters] = useState(startFilters);
  const [filtersHaveChanged, _filtersHaveChanged] = useState(false);
  const [filtersHaveChangedAnyTime, _filtersHaveChangedAnyTime] = useState(false);

  useEffect(() => {
    console.log(filters, setup);

    if (!filters) return;
    if (setup) {
      setup = false;
      return;
    }

    _filtersHaveChanged(true);
    _filtersHaveChangedAnyTime(true);
  }, [filters]);

  const handleChange = name => (_, value) => {
    _filters(prevState => {
      let filtersChanged = { ...prevState, [name]: value };

      if (name === 'state') delete filtersChanged['city'];

      return filtersChanged;
    });
  };

  const applyFilters = () => {
    _filtersHaveChanged(false);
    if (onChange !== undefined) {
      onChange(filters);
    }
  };

  const restoreFilters = () => {
    //restore filters
    _filters(startFilters);

    setTimeout(() => {
      _filtersHaveChangedAnyTime(false);
      _filtersHaveChanged(false);
    }, 200);

    if (onChange !== undefined) {
      onChange(filters);
    }
  };

  return (
    <>
      <div className="header">
        <div className="heading">
          <svg
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="css-i6dzq1"
          >
            <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
          </svg>
          <div className="title">Filtros</div>
        </div>

        {body === 'map' && (
          <div className="close" onClick={() => onSidebar('empty')}>
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="css-i6dzq1"
            >
              <polyline points="18 15 12 9 6 15"></polyline>
            </svg>
          </div>
        )}
      </div>
      <div className="body">
        <div className="filter-group">
          <div className="group-title">Político-administrativo</div>

          <AutocompleteAsyncStyled
            randomID={randomID}
            label="estado"
            onChange={handleChange('state')}
            value={filters['state']}
            url={`${process.env.REACT_APP_SERVER}tmp-service/uf`}
          />

          <AutocompleteAsyncStyled
            randomID={randomID}
            label="cidade"
            onChange={handleChange('city')}
            disabled={!filters['state']}
            value={filters['city']}
            url={`${process.env.REACT_APP_SERVER}tmp-service/uf/${filters['state']}/mun`}
          />
        </div>
        <Divider sx={{ my: 2 }} />
        <div className="filter-group">
          <div className="group-title">Natural</div>
          <AutocompleteStyled
            onChange={handleChange('biome')}
            textFieldProps={{ label: 'bioma', inputProps: { autoComplete: randomID } }}
            options={[{ label: 'bioma' }]}
            disabled
          />
        </div>
        <Divider sx={{ my: 2 }} />
        <div className="filter-group">
          <div className="group-title">Tipo de imóvel</div>
          <FormGroup>
            <FormControlLabel
              checked={filters['ruralProperties']}
              onChange={handleChange('ruralProperties')}
              control={<Checkbox color="primary" />}
              label="Imóveis rurais (IRU)"
            />

            <FormControlLabel
              checked={filters['settlements']}
              onChange={handleChange('settlements')}
              control={<Checkbox color="primary" />}
              label="Assentamentos (AST)"
            />

            <FormControlLabel
              checked={filters['peopleAndCommunities']}
              onChange={handleChange('peopleAndCommunities')}
              control={<Checkbox color="primary" />}
              label="Povos e comunidades trad. (PCT)"
            />
          </FormGroup>
        </div>
        <Divider sx={{ my: 2 }} />
        <div className="filter-group">
          <div className="group-title">Uso do solo</div>
          <FormGroup>
            <FormControlLabel
              checked={filters['pasture']}
              onChange={handleChange('pasture')}
              control={<Checkbox color="primary" />}
              label="Pastagem"
            />

            <FormControlLabel
              checked={filters['agriculture']}
              onChange={handleChange('agriculture')}
              control={<Checkbox color="primary" />}
              label="Agricultura"
            />

            <FormControlLabel
              checked={filters['nativeVegetation']}
              onChange={handleChange('nativeVegetation')}
              control={<Checkbox color="primary" />}
              label="Vegetação nativa"
            />
          </FormGroup>
        </div>
        <Divider sx={{ my: 2 }} />
        <div className="filter-group">
          <div className="group-title">Desmatamento</div>
          <Typography variant="body2">Anos</Typography>
          <BnlSlider
            valueLabelDisplay="on"
            value={filters['deforestation']}
            onChangeCommitted={handleChange('deforestation')}
            min={2008}
            max={new Date().getFullYear()}
          />
          <Typography variant="body2">Área original (ha)</Typography>
          <BnlSlider
            valueLabelDisplay="on"
            getAriaLabel={() => 'Temperature range'}
            onChangeCommitted={handleChange('area')}
            value={filters['area']}
            min={1}
            max={12.5}
            step={0.25}
            track="inverted"
          />
          <Typography variant="body2">Área sobreposta (ha)</Typography>
          <BnlSlider
            valueLabelDisplay="on"
            getAriaLabel={() => 'Temperature range'}
            onChangeCommitted={handleChange('area_sobreposta')}
            value={filters['area_sobreposta']}
            min={1}
            max={12.5}
            step={0.25}
            track="inverted"
          />
        </div>
        <Divider sx={{ my: 2 }} />
        <div className="filter-group">
          <Typography className="group-title">Sobreposição</Typography>
          <FormGroup>
            <FormControlLabel
              checked={filters['embargoedArea']}
              onChange={handleChange('embargoedArea')}
              control={<Checkbox color="primary" />}
              label="Área embargada"
            />

            <FormControlLabel
              checked={filters['indigenousTerritory']}
              onChange={handleChange('indigenousTerritory')}
              control={<Checkbox color="primary" />}
              label="Território Indígena"
            />

            <FormControlLabel
              checked={filters['fullProtectionUC']}
              onChange={handleChange('fullProtectionUC')}
              control={<Checkbox color="primary" />}
              label="UC de Proteção Integral"
            />

            <FormControlLabel
              checked={filters['quilombolaTerritory']}
              onChange={handleChange('quilombolaTerritory')}
              control={<Checkbox color="primary" />}
              label="Território Quilombola"
              disabled
            />
          </FormGroup>
        </div>
        <Divider sx={{ my: 2 }} />
        <div className="filter-group">
          <div className="group-title" style={{ color: '#aaa' }}>
            Área de influência dos frigoríficos
          </div>
          <AutocompleteStyled
            onChange={handleChange('influenceOfSlaughterhouses')}
            multiple
            disabled
            textFieldProps={{ inputProps: { autoComplete: randomID } }}
            options={[
              { label: 'Frigorífico1', year: 1994 },
              { label: 'Frigorífico2', year: 1972 },
            ]}
          />
          <Typography variant="body2" sx={{ mb: 3 }} style={{ color: '#aaa' }}>
            Raio de influência (km)
          </Typography>
          <BnlSlider disabled valueLabelDisplay="on" onChangeCommitted={handleChange('radiusOfInfluence')} defaultValue={150} max={300} />
        </div>
      </div>
      <div className="footer" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          style={{
            fontWeight: 'bold',
            /* color: '#fff', */
            fontFamily: 'Gotham',
            fontSize: '12px',
            boxShadow: 'none',
            marginRight: '5px',
          }}
          variant="outlined"
          onClick={restoreFilters}
          disabled={!filtersHaveChangedAnyTime}
        >
          restaurar padrão
        </Button>

        <Button
          style={{
            fontWeight: 'bold',
            color: '#fff',
            fontFamily: 'Gotham',
            fontSize: '12px',
            boxShadow: 'none',
          }}
          variant="contained"
          onClick={applyFilters}
          disabled={!filtersHaveChanged}
        >
          aplicar
        </Button>
      </div>
    </>
  );
}

const w = '100',
  h = '95'; /* thumb dimensions */

function SideBarSearch({ cars, onChange, onSidebar, selectedCAR, body, onSearch, onRemove }) {
  const [carsField, _carsField] = useState('');

  const handleSearch = async () => {
    /* para cada car, passar no regular exp e eliminar, inclusive no campo, o que nao se encaixa */
    const regxp = /[A-Z]{2}-\d*-.*/;
    let code_list = carsField.split('\n').filter(c => regxp.test(c));
    _carsField(code_list.join('\n'));

    await onSearch(code_list);

    _carsField('');
  };

  const remove = (e, id) => {
    e.stopPropagation();
    onRemove(id);
  };

  return (
    <>
      <div className="header">
        <div className="heading">
          <svg viewBox="0 0 24 24" stroke="#7386E6" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
          <div className="title">Consultar</div>
        </div>
        {body === 'map' && (
          <div className="close" onClick={() => onSidebar('empty')}>
            <svg viewBox="0 0 24 24" stroke="#C2C4CC" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        )}
        {body === 'no_map' && (
          <div className="close" onClick={() => onSidebar('filters')}>
            <svg viewBox="0 0 24 24" stroke="#C2C4CC" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        )}
        <div className="search_input" style={{ display: 'flex', alignItems: 'start' }}>
          <TextField
            /* style={{ width: '100%' }} */
            fullWidth
            multiline
            label="Insira o CAR"
            id="outlined-start-adornment"
            value={carsField}
            onChange={e => _carsField(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="search" onClick={handleSearch} edge="end">
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="#DBDDE6"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            style={{
              fontWeight: 'bold',
              color: '#fff',
              fontFamily: 'Gotham',
              fontSize: '12px',
              boxShadow: 'none',
              marginLeft: '10px',
            }}
            variant="contained"
            onClick={() => onRemove()}
          >
            limpar
          </Button>
        </div>
      </div>
      <div className="body">
        {!cars.length && (
          <div className="search_message">
            <img src={consulta} alt="consulta" />
            <div className="text">
              Busque um ou mais CARs
              <br />e veja o relatório dos imóveis
            </div>
          </div>
        )}

        {cars && (
          <ul className="result_cars">
            {cars.map(car => (
              <li className={selectedCAR && selectedCAR === car.cod_imovel ? 'active' : ''} onClick={() => onChange(car)} key={car.id}>
                <div>
                  <img
                    src={`https://newgeoserver.imaflora.org/geoserver/cattle-on-track/wms?service=WMS&version=1.1.0&request=GetMap&layers=cattle-on-track:pa_br_uso_mapbiomas_col_5_2018,cattle-on-track:sicar_bi_am_imoveis&cql_filter=include;id=${car.id}&bbox=${car.bbox}&width=${w}&height=${h}&srs=EPSG:4674&styles=&format=image/png`}
                    alt=""
                  />
                  <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '0px', right: '0px', fontSize: '1.1em' }} onClick={e => remove(e, car.id)}>
                      x
                    </div>
                    <div className="title">{car.cod_imovel}</div>
                    <div>
                      {car.municipio}, {car.estado}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="footer"></div>
    </>
  );
}
