import React from 'react';
import './styles.scss';

export default function Card({ title = "Título do gráfico", classes='', loading=false, children, bottom}) {
  return (
    <>
      <div className={`card ${classes} ${loading ? 'loading' : ''}`}>
        <div className="header">
          <div className="title">{title}</div>
        </div>
        <div className="body" style={{ justifyContent: bottom ? 'flex-end' : 'unset' }}>
          {children}
        </div>
      </div>
    </>
  );
}
