// react
import React, { useState, useEffect, useRef } from 'react';

// openlayers
import Map from 'ol/Map';
import View from 'ol/View';

import TileLayer from 'ol/layer/Tile';

import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';

import XYZ from 'ol/source/XYZ';

import { transform, fromLonLat /* , Projection */, transformExtent } from 'ol/proj';

import { Style, Fill, Stroke } from 'ol/style';

import { MVT } from 'ol/format';

// import { TileDebug } from 'ol/source';

import Overlay from 'ol/Overlay';

import './styles.scss';

import OLWMSLayer from './myOL/OLWMSLayer';

import axios from 'axios';

import FloatingBoard from '../FloatingBoard';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { useSnackbar } from 'notistack';

const defaultStyle = new Style({
  fill: new Fill({
    color: '#ADD8E6',
  }),
  stroke: new Stroke({
    color: '#880000',
    width: 1,
  }),
});

const featuredtStyle = new Style({
  fill: new Fill({
    color: 'red',
  }),
  stroke: new Stroke({
    color: 'red',
    width: 4,
  }),
});

function MapWrapper({ filters, layers, dispatch, selectedCAR, selectedCARId, body, onChange, onSidebar, onBody, generalInfo, onCARAction, focusBBOX }) {
  const { enqueueSnackbar } = useSnackbar();

  // set intial state
  const [map, setMap] = useState();
  const [filteredIDs, setFilteredIDs] = useState(null);

  const [featureClicked, _featureClicked] = useState(null);
  const [featureClickedInfo, _featureClickedInfo] = useState(null);
  const [openFCI, _openFCI] = useState(false);

  // pull refs
  const mapElement = useRef();
  const overlayElement = useRef();

  // create state ref that can be accessed in OpenLayers onclick callback function
  //  https://stackoverflow.com/a/60643670
  const mapRef = useRef();
  mapRef.current = map;

  const overlayRef = useRef();
  const vectorRef = useRef();
  const vector2Ref = useRef();

  useEffect(() => {
    if (!filters) return;

    (async () => {
      let query = Object.keys(filters)
        .filter(k => !!filters[k])
        .map(k => `${k}=${filters[k]}`)
        .join('&');
      let { data } = await axios.get(`${process.env.REACT_APP_SERVER}tmp-service/imoveis/filtered?${query}`);

      setFilteredIDs(data.ids);
    })();
  }, [filters]);

  useEffect(() => {
    if (!featureClicked) return;

    (async () => {
      let { data } = await axios.get(`${process.env.REACT_APP_SERVER}tmp-service/imoveis/get_info?ids=${featureClicked.join(',')}`);

      console.log(data);

      _featureClickedInfo(data);
      _openFCI(true);

      _featureClicked(null);
    })();
  }, [featureClicked]);

  // initialize map on first render - logic formerly put into componentDidMount
  useEffect(() => {
    // map click handler
    const handleMapClick = async event => {
      console.log('CLICK');

      const features = mapRef.current.getFeaturesAtPixel(event.pixel);
      if (features.length === 0) {
        console.log('NONE');
        return;
      } else {
        let featuresIDs = features.map(f => f.getProperties().id);
        _featureClicked(featuresIDs);
      }
    };

    // create map
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        // USGS Topo
        new TileLayer({
          source: new XYZ({
            url: 'https://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
          }),
        }),
        /* new TileLayer({
          source: new TileDebug(),
        }), */
      ],
      view: new View({
        center: transform([-56, -6], 'EPSG:4326', 'EPSG:3857'),
        zoom: 5,
      }),
      controls: [
        // new FullScreen({}),
      ],
    });

    // set map onclick handler
    initialMap.on('singleclick', handleMapClick);

    // save map and vector layer references to state
    setMap(initialMap);
  }, []);

  useEffect(() => {
    if (!map) return;

    overlayRef.current = new Overlay({
      position: fromLonLat([-52.94785, -3.6836]),
      positioning: 'center-center',
      element: overlayElement.current,
      stopEvent: false,
    });

    map.addOverlay(overlayRef.current);
  }, [map]);

  useEffect(() => {
    function filteredStyle(feature, resolution) {
      
      if(selectedCARId && selectedCARId === feature.getProperties().id) {
        return featuredtStyle;
      }

      if (!filteredIDs) {
        return defaultStyle;
      }
      if (filteredIDs.includes(feature.getProperties().id)) {
        return defaultStyle;
      }
    }

    if (!map) return;

    if (vectorRef.current) {
      map.removeLayer(vectorRef.current);
    }
    if (vector2Ref.current) {
      map.removeLayer(vector2Ref.current);
    }

    if (!layers.imoLayer) return;

    vectorRef.current = new VectorTileLayer({
      style: filteredStyle,
      source: new VectorTileSource({
        tilePixelRatio: 1,
        format: new MVT(),
        url: 'https://newgeoserver.imaflora.org/geoserver/gwc/service/tms/1.0.0/cattle-on-track%3Abnl_sicar_bi_am_imoveis_sim@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf',
      }),
      minZoom: 0,
      maxZoom: 8,
      opacity: 0.6, // layers.imoLayer.opacity
    });

    vector2Ref.current = new VectorTileLayer({
      style: filteredStyle,
      source: new VectorTileSource({
        tilePixelRatio: 1,
        format: new MVT(),
        url: 'https://newgeoserver.imaflora.org/geoserver/gwc/service/tms/1.0.0/cattle-on-track%3Asicar_bi_am_imoveis@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf',
      }),
      minZoom: 8,
      maxZoom: 22,
      opacity: 0.6,
    });

    vectorRef.current.setZIndex(99);
    vector2Ref.current.setZIndex(99);

    if (filters) {
      map.addLayer(vectorRef.current);
      map.addLayer(vector2Ref.current);
    }
    
  }, [map, filteredIDs, filters, layers.imoLayer, selectedCARId]);

  useEffect(()=>{
    if(!!focusBBOX && !!mapRef.current) {

      const [x1, y1, x2, y2] = focusBBOX.split(',');
      
      // transform bbox from EPSG:4326 to EPSG:3857
      const extent = transformExtent([x1, y1, x2, y2], 'EPSG:4326', 'EPSG:3857');

      mapRef.current.getView().fit(extent, { duration: 1000 });
    }
  },[focusBBOX, map]);

  const goToCAR = i => {
    onCARAction('goto', i);
    _openFCI(false);
  };

  const addCAR = i => {
    onCARAction('add', i);
    /* _openFCI(false); */
  };

  const captureCAR = i => {
    navigator.clipboard.writeText(i.cod_imovel);

    enqueueSnackbar('O número do CAR foi copiado para área de transferência', {
      /* variant: 'info', */
      autoHideDuration: 2000,
      /* persist: true, */
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  // render component
  return (
    <>
      <div className="map">
        <div ref={mapElement} className="map-container">
          {' '}
          {/* TODO: criar um componente e passar o map para todos os filhos */}
          <FloatingBoard selectedCAR={selectedCAR} body={body} onSidebar={onSidebar} onChange={onChange} onBody={onBody} generalInfo={generalInfo} />
          
          {Object.values(layers).map(l => {
            // console.log(l)

            if(!l.show) return null;

            return <OLWMSLayer
              map={map}
              url={l.url}
              layers={l.layer}
              opacity={l.opacity}
            />

          })}
        </div>
      </div>

      <Dialog open={openFCI} onClose={() => _openFCI(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Lista de imóveis sobrepostos'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ul>
              {featureClickedInfo &&
                featureClickedInfo.map(i => (
                  <li style={{ cursor: 'pointer', display: 'flex' }} key={i.id}>
                    <div onClick={() => goToCAR(i)}>{i.cod_imovel}</div>
                    <small onClick={() => goToCAR(i)}>[car]</small>
                    <small onClick={() => captureCAR(i)}>[cap]</small>
                    <small onClick={() => addCAR(i)}>[adi]</small>
                  </li>
                ))}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => _openFCI(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MapWrapper;
