import dayjs from 'dayjs';

export const startFilters = {
    ruralProperties: true,
    settlements: false,
    peopleAndCommunities: false,

    pasture: true,
    agriculture: false,
    nativeVegetation: false,

    embargoedArea: false,
    indigenousTerritory: false,
    fullProtectionUC: false,
    quilombolaTerritory: false,

    deforestation: [2016, dayjs().year()],
    area: 6.25,
    area_sobreposta: 6.25,
};

export const filtersQueryBuilder = (filters) => {
    return Object.keys(filters)
        .filter(k => !!filters[k])
        .map(k => `${k}=${filters[k]}`)
        .join('&');
}

export const palette = ['#543005','#ae8c65', '#d2bda4', '#261602'];