import React, { useState } from 'react';
import './styles.scss';

import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Button } from '@mui/material';

import classnames from 'classnames';

import BnlSlider from '../../components/BnlSlider';

export default function Layers({ layers, dispatch, sidebar }) {
  const [show, _show] = useState(false);

  const [activeLegend, _activeLegend] = useState(null);

  const [showLayerInfo, _showLayerInfo] = useState(null);

  const onToggleLegend = id => {
    console.log(id);
    _activeLegend(al => (al === id ? null : id));
  };

  const openInfo = layer => {
    _showLayerInfo(layer);
  };

  return (
    <>
      <div className={`layers ${sidebar === 'empty' ? 'move' : ''}`}>
        <div className="toggle" onClick={() => _show(!show)}>
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 32 32">
              <g id="Grupo_111" data-name="Grupo 111" transform="translate(1215 -2210)">
                <circle id="Elipse_3" data-name="Elipse 3" cx="16" cy="16" r="16" transform="translate(-1215 2210)" fill="#783b1f" />
                <g id="Icon_feather-layers" data-name="Icon feather-layers" transform="translate(-1207 2218)">
                  <path
                    id="Caminho_7"
                    data-name="Caminho 7"
                    d="M11,3,3,7l8,4,8-4Z"
                    transform="translate(-3 -3)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Caminho_8"
                    data-name="Caminho 8"
                    d="M3,25.5l8,4,8-4"
                    transform="translate(-3 -13.5)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Caminho_9"
                    data-name="Caminho 9"
                    d="M3,18l8,4,8-4"
                    transform="translate(-3 -10)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="text">{!show ? 'Camadas' : 'Recolher camadas'}</div>
        </div>
        <div className={classnames('layers-list', { show })}>
          {Object.keys(layers).map(k => (
            <EachLayer
              key={k}
              id={k}
              title={layers[k].title}
              image={layers[k].svg_url}
              active={layers[k].show}
              legend={layers[k].legend}
              activeLegend={activeLegend}
              onToggleLegend={onToggleLegend}
              opacity={layers[k].opacity}
              onClick={() => dispatch({ do: 'toggle', layer: k })}
              onChangeOpacity={opacity => dispatch({ do: 'change-opacity', layer: k, opacity })}
              openInfo={() => openInfo(layers[k])}
            />
          ))}
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" open={!!showLayerInfo} onClose={() => _showLayerInfo(false)}>
        <DialogTitle>Informações sobre a camada "{showLayerInfo?.title}"</DialogTitle>
        <DialogContent>
          <Box>
            <table className="info">
              <tr>
                <td className="title">Descrição:</td>
                <td className="value">{showLayerInfo?.description}</td>
              </tr>
              <tr>
                <td className="title">Mais informações:</td>
                <td className="value"><a rel="noreferrer" href={showLayerInfo?.moreinfo} target="_blank">{showLayerInfo?.moreinfo}</a></td>
              </tr>
              <tr>
                <td className="title">Atribuição:</td>
                <td className="value">{showLayerInfo?.attribution}</td>
              </tr>
              <tr>
                <td className="title">Formato:</td>
                <td className="value">{showLayerInfo?.format}</td>
              </tr>
              <tr>
                <td className="title">Camada:</td>
                <td className="value">{showLayerInfo?.layer}</td>
              </tr>
            </table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => _showLayerInfo(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function EachLayer({ id, title, image, legend, active = false, onClick, opacity, activeLegend, onToggleLegend, onChangeOpacity, openInfo }) {
  const [opacityMode, _opacityMode] = useState(false);

  const toggleOpacityMode = () => {
    _opacityMode(tm => !tm);
  };

  const handleClick = () => {
    _opacityMode(false);
    onClick();
  };

  return (
    <div className="item">
      <div className={classnames('item-inner', { active: active })}>
        <div className="item-toggle" onClick={handleClick}>
          <div className="icon">
            <img src={image} alt="layer" width="32" />
          </div>
          <div className="hide">
            <svg id="Componente_7_2" data-name="Componente 7 – 2" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
              <path id="Caminho_232" data-name="Caminho 232" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" fill="#dbdde6" />
              <g id="Icon_feather-eye-off" data-name="Icon feather-eye-off" transform="translate(6.857 6.857)">
                <path
                  id="Caminho_70"
                  data-name="Caminho 70"
                  d="M9.42,12.517a4.708,4.708,0,0,1-2.777.963C3.37,13.48,1.5,9.74,1.5,9.74A8.626,8.626,0,0,1,3.866,6.963m1.8-.851A4.264,4.264,0,0,1,6.643,6c3.273,0,5.143,3.74,5.143,3.74a8.649,8.649,0,0,1-1.01,1.491m-3.142-.5A1.4,1.4,0,1,1,5.652,8.749"
                  transform="translate(-1.5 -4.597)"
                  fill="none"
                  stroke="#646569"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <path
                  id="Caminho_71"
                  data-name="Caminho 71"
                  d="M1.5,1.5,11.786,11.786"
                  transform="translate(-1.5 -1.5)"
                  fill="none"
                  stroke="#646569"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </div>
        </div>

        {!opacityMode && (
          <>
            <div className="text">{title}</div>

            <div className="controls">
              <div className="legend" onClick={() => onToggleLegend(id)}>
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </div>

              <div className="opacity" onClick={toggleOpacityMode}>
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                </svg>
              </div>

              <div className="more" onClick={openInfo}>
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
                </svg>
              </div>
            </div>
          </>
        )}

        {opacityMode && (
          <>
            <div style={{ width: '10rem' }}>
              <BnlSlider valueLabelDisplay="on" value={opacity} onChangeCommitted={(_, value) => onChangeOpacity(value)} min={0} max={100} />
            </div>

            <div className="controls">
              <div className="close-opacity" onClick={toggleOpacityMode}>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
            </div>
          </>
        )}
      </div>
      {id === activeLegend && (
        <div className="legend_image">
          <img src={legend} alt="legend" />
        </div>
      )}
    </div>
  );
}
