import React from 'react';
import './styles.scss';

export default function RoundButton({ sidebar, onSidebar }) {
  return (
    <div className='round-buttons-wrapper'>
    <div className={`round-button filter ${sidebar === "filters" ? "active" : ""}`} onClick={()=>onSidebar('filters')}>
      <svg
        viewBox="0 0 24 24"
        width="24"
        height="24"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="css-i6dzq1"
      >
        <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
      </svg>
    </div>
    <div className={`round-button search ${sidebar === "search" ? "active" : ""}`} onClick={()=>onSidebar('search')}>
      <svg
        viewBox="0 0 24 24"
        width="24"
        height="24"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="css-i6dzq1"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
    </div>

  </div>
  );
}
