import React, { useState, useEffect, useReducer } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';


/* import styled from 'styled-components' TODO: arquivo irmao */

import { Switch, Route } from 'react-router-dom';

import MainMap from './components/MainMap';
import Panel from './components/Panel';
import CAR from './components/CAR';

import SideBar from './components/SideBar';
import NavBar from './components/NavBar';
import Layers from './components/Layers';

import axios from 'axios';

import { startFilters, filtersQueryBuilder } from './utils';

import { SnackbarProvider, useSnackbar } from 'notistack';

const theme = createTheme({
  palette: {
    primary: {
      main: '#78b637',
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route>
            <SnackbarProvider maxSnack={3}>
              <Home />
            </SnackbarProvider>
          </Route>
        </Switch>
      </ThemeProvider>
    </>
  );
}

function Home() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /* TODO: todos estes estados devem estar em hooks e/ou contexto OU redux like */
  const [selectedCAR, _selectedCAR] = useState(null);
  const [selectedCARId, _selectedCARId] = useState(null);
  const [sidebar, _sidebar] = useState('filters');
  const [filters, _filters] = useState(startFilters);
  const [body, _body] = useState('map');

  const [cars, _cars] = useState([]);

  const [focusBBOX, _focusBBOX] = useState(null);

  const [generalInfo, dispatchInfo] = useReducer(
    (state, action) => {
      return { ...state, [action.info]: action.data };
    },
    {
      quantidade: null,
      area: null,
      municipios: null,
      estados: null,
      ucs: null,
      tis: null,
      emb: null,
    },
  );



  useEffect(()=>{

    (async () => {
      let { data } = await axios.get(`${process.env.REACT_APP_SERVER}tmp-service/layers`);

      dispatch({ do: 'init', data });
    })();
  },[])

  useEffect(() => {
    (async () => {
      let query = filtersQueryBuilder(filters);

      console.log({ query })

      let { data: { data } } = await axios.get(`${process.env.REACT_APP_SERVER}car/summary-all?${query}`);
      dispatchInfo({ info: 'quantidade', data: data.count });
      dispatchInfo({ info: 'municipios', data: data.count_mun });
      dispatchInfo({ info: 'area', data: data.area_ha });
      dispatchInfo({ info: 'estados', data: data.count_uf });
      dispatchInfo({ info: 'ucs', data: data.count_ucs });
      dispatchInfo({ info: 'tis', data: data.count_tis });
      dispatchInfo({ info: 'emb', data: data.count_emb });
    })();
  }, [filters]);

  const [layers, dispatch] = useReducer(
    (state, action) => {
      if(action.do === 'init') {
        let layers = {}
        action.data.forEach((l, idx)=>{
          layers[`layer_${idx}`] = { 
            show: false, 
            opacity: 60, 
            title: l.name, 
            url: l.config.url, 
            layer: l.config.params.layers, 
            svg_url: l.config.svg_url, 
            legend: l.config.legend,
            description: l.config.description,
            moreinfo: l.config.moreinfo,
            attribution: l.config.params.attribution,
            format: l.config.params.format,
          };
        })

        return {...layers,...state};
      }

      if(action.do === 'toggle')
        return { ...state, [action.layer]: { ...state[action.layer], show: !state[action.layer].show } };
      
      if(action.do === 'change-opacity')
        return { ...state, [action.layer]: { ...state[action.layer], opacity: action.opacity } };

      return state;
    },
    {
      imoLayer: { svg_url: 'http://www.ludolab.com.br/bnl_svgs/imoveis.svg', /* TODO */ show: true, opacity: 100, title: 'Imóveis Rurais' },
    },
  );

  const handleChange = (data) => {
    if (sidebar === 'filters') _filters(data);
    else {
      _selectedCAR(data.cod_imovel);
      _selectedCARId(data.id);
    }
  }

  const onSidebar = (sidebar) => {
    console.log(sidebar)
    _selectedCAR(null);
    _selectedCARId(null);
    _sidebar(sidebar);
  }

  const onShowInMap = async () => {
    const snack = enqueueSnackbar('Carregando...', {
      /* variant: 'info', */
      persist: true,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });

    let { data } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}`);

    closeSnackbar(snack);

    _focusBBOX(null);
    _focusBBOX(data.bbox);

    _body('map');
  }

  const handleCARAction = (action, car) => {
    console.log({ action, car })
    
    if (action === 'add') {
      _cars([car, ...cars.filter(c => c.id !== car.id)]);
      _sidebar('search');
    }

    if (action === 'goto') {
      _sidebar('search');
      _body('no_map');
      _selectedCAR(car.cod_imovel);
      _selectedCARId(car.id);
    }
  }

  const onBody = (body) => {
    console.log(body)
    _body(body);
  }

  const handleSearch = async(code_list) => {

    /* Nao busca por CARs ja presentes na lista */
    code_list = code_list.filter(i => !cars.find(c => c.cod_imovel === i));

    if(!code_list.length) return;

    /* buscar e apresenta cars */
    let {
      data: { cars: newCars },
    } = await axios.get(`${process.env.REACT_APP_SERVER}car/?code_list=${code_list.join(',')}`);

    if (cars.length === 0 && newCars.length) handleChange(newCars[0].cod_imovel);

    _cars([...newCars, ...cars]);
  }

  const handleRemove = (id) => {
    if(!id) _cars([]); /* remove all */
    else _cars([...cars.filter(c => c.id !== id)]); /* remove one */
  }

  return (
    <>

      <div className="page-wrapper">
        <NavBar sidebar={sidebar} onSidebar={onSidebar} />
        <SideBar cars={cars} onSearch={handleSearch} onRemove={handleRemove} onChange={handleChange} sidebar={sidebar} onSidebar={onSidebar} selectedCAR={selectedCAR} body={body} />

        {body === 'map' &&
          <>
            <MainMap filters={filters} layers={layers} dispatch={dispatch} selectedCAR={selectedCAR} selectedCARId={selectedCARId} body={body} onSidebar={onSidebar} onChange={(target) => _body(target)} onBody={onBody} generalInfo={generalInfo} onCARAction={handleCARAction} focusBBOX={focusBBOX} />
            <Layers sidebar={sidebar} layers={layers} dispatch={dispatch} />
          </>
        }
        {body === 'no_map' && !selectedCAR &&
          <Panel selectedCAR={selectedCAR} body={body} onChange={(target) => _body(target)} generalInfo={generalInfo} />
        }

        {body === 'no_map' && selectedCAR &&
          <CAR selectedCAR={selectedCAR} generalInfo={generalInfo} onShowInMap={onShowInMap} />
        }
      </div>
    </>
  );
}

/* TODO: move components and think aboux UX -> CSS -> Components methodlogy */

export default App;
