import React, { useState, useEffect } from 'react';
import './styles.scss';

import FloatingBoard from '../FloatingBoard';
import Card from '../Card';

import Chart from 'react-apexcharts';

import axios from 'axios';
import numeral from 'numeral';

import { palette } from '../../utils';

export default function Panel({ selectedCAR, generalInfo, onShowInMap }) {
  return (
    <>
      <div className="panel">
        <div className="panel-header">
          <FloatingBoard selectedCAR={selectedCAR} isCAR={true} generalInfo={generalInfo} />
        </div>

        <div className="panel-body">
          <Bloco1 selectedCAR={selectedCAR} onShowInMap={onShowInMap} />

          <Bloco2 selectedCAR={selectedCAR} />

          <Bloco5 selectedCAR={selectedCAR} />

          <Bloco3 selectedCAR={selectedCAR} />

          <Bloco4 selectedCAR={selectedCAR} />
        </div>
      </div>
    </>
  );
}

function Bloco4({ selectedCAR }) {
  const [sobreposicao, _sobreposicao] = useState(null);
  const [sobreposicaoTI, _sobreposicaoTI] = useState(null);
  const [sobreposicaoUC, _sobreposicaoUC] = useState(null);

  const [loading, _loading] = useState(true);

  useEffect(() => {
    (async () => {
      _loading(true);
      _sobreposicao(null);
      let {
        data: { result },
      } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}/sobreposicao`);
      _sobreposicao(result);

      _sobreposicaoTI(null);
      let { data: sTI } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}/sobreposicao_ti`);
      _sobreposicaoTI(sTI);

      _sobreposicaoUC(null);
      let { data: sUC } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}/sobreposicao_uc`);
      _sobreposicaoUC(sUC);
      _loading(false);
    })();
  }, [selectedCAR]);

  return (
    <Card classes="overlaps" title="Sobreposições" loading={loading}>
      {sobreposicaoTI && sobreposicaoUC && (
        <div className="row">
          <div className="item">
            <strong>Terras indigenas</strong>
            <br />
            {sobreposicaoTI.count} sobreposicoes
            <br />
            {numeral(parseFloat(sobreposicaoTI.area_ha)).format('0,0.00')} ha
          </div>
          <div className="item">
            <strong>Unidades de conservacao</strong>
            <br />
            {sobreposicaoUC.count} sobreposicoes
            <br />
            {numeral(parseFloat(sobreposicaoUC.area_ha)).format('0,0.00')} ha
          </div>
        </div>
      )}

      {sobreposicao && (
        <table>
          <thead>
            <tr>
              <th>nome</th>
              <th>categoria</th>
              <th>grupo</th>
              <th>fonte</th>
              <th>área</th>
            </tr>
          </thead>

          <tbody>
            {sobreposicao.map((s, idx) => (
              <tr key={idx}>
                <td>{s.nome}</td>
                <td>{s.categoria}</td>
                <td>{s.grupo}</td>
                <td>{s.fonte}</td>
                <td>{numeral(parseFloat(s.area_ha)).format('0,0.00')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Card>
  );
}

function Bloco3({ selectedCAR }) {
  const [selectedCarData, _selectedCarData] = useState(null);
  const [loading, _loading] = useState(true);

  useEffect(() => {
    (async () => {
      _selectedCarData(null);
      _loading(true);
      let {
        data: { result },
      } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}/embargos`);
      _selectedCarData(result);
      _loading(false);
    })();
  }, [selectedCAR]);

  return (
    <Card title="Embargos" loading={loading}>
      {selectedCarData && (
        <table>
          <thead>
            <tr>
              <th>tad</th>
              <th>data tad</th>
              <th>status</th>
              <th>qtd áreas</th>
              <th>área</th>
              <th>num. auto</th>
            </tr>
          </thead>

          <tbody>
            {selectedCarData.map(e => (
              <tr key={e.numero_tad}>
                <td>{e.numero_tad}</td>
                <td>{e.data_tad}</td>
                <td>{e.status_atu}</td>
                <td>{numeral(parseFloat(e.qtd_area_e)).format('0,0.00')}</td>
                <td>{numeral(parseFloat(e.area_ha)).format('0,0.00')}</td>
                <td>{e.num_auto_i}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Card>
  );
}

function Bloco2({ selectedCAR }) {
  return (
    <Uso selectedCAR={selectedCAR} />
  );
}
function Bloco5({ selectedCAR }) {
  return <Desmatamento selectedCAR={selectedCAR} />;
}

const w = '735',
  h = '335'; /* thumb dimensions */

function Bloco1({ selectedCAR, onShowInMap }) {
  const [selectedCarData, _selectedCarData] = useState(null);
  const [selectedCARPastagem, _selectedCARPastagem] = useState(null);
  const [thumb, _thumb] = useState(null);

  const [loading, _loading] = useState(true);

  useEffect(() => {
    _selectedCARPastagem(null);
    _selectedCarData(null);

    (async () => {
      _loading(true);

      let { data } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}`);
      _selectedCarData(data);

      _thumb(
        `https://newgeoserver.imaflora.org/geoserver/cattle-on-track/wms?service=WMS&version=1.1.0&request=GetMap&layers=cattle-on-track:pa_br_uso_mapbiomas_col_5_2018,cattle-on-track:sicar_bi_am_imoveis&cql_filter=include;id=${data.id}&bbox=${data.bbox}&width=${w}&height=${h}&srs=EPSG:4674&styles=&format=image/png`,
      );

      _loading(false);

      let {
        data: { area },
      } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}/pastagem`);
      _selectedCARPastagem(area);
    })();
  }, [selectedCAR]);

  return (
    <Card classes="abstract large" title="Bloco 1" loading={loading}>
      {selectedCarData && (
        <>
          <div className="map-preview">
            {thumb && <img src={thumb} alt="" />}
            <div className="expand" onClick={onShowInMap}>
              <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="css-i6dzq1"
              >
                <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
              </svg>
              VER MAPA
            </div>
          </div>
          <div className="info">
            <div className="title">Propriedade</div>
            <div className="row">
              <div className="item">
                <div className="label">Area:</div>
                <div className="value">{numeral(parseFloat(selectedCarData.area)).format('0,0.00')} (ha)</div>
              </div>
              <div className="item">
                <div className="label">Módulos Fiscais:</div>
                <div className="value">{numeral(parseFloat(selectedCarData.m_fiscal)).format('0,0.000')}</div>
              </div>
              <div className="item">
                <div className="label">Tamanho:</div>
                <div className="value">{selectedCarData.tamanho}</div>
              </div>
            </div>
            <div className="title">Localização</div>
            <div className="row">
              <div className="item">
                <div className="label">Município:</div>
                <div className="value">{selectedCarData.municipio}</div>
              </div>
              <div className="item">
                <div className="label">Estado:</div>
                <div className="value">{selectedCarData.estado}</div>
              </div>
              <div className="item">
                <div className="label">Bioma:</div>
                <div className="value">Amazônia</div>
              </div>
            </div>
            <div className="title">Pastagem</div>
            <div className="row">
              <div className="item">
                <div className="label">Area de pastagem:</div>
                <div className="value">{selectedCARPastagem ? numeral(parseFloat(selectedCARPastagem)).format('0,0.00') : '-'} (ha)</div>
              </div>
            </div>
          </div>
        </>
      )}
    </Card>
  );
}

function Uso({ selectedCAR }) {
  const [series, _series] = useState(null);
  const [options, _options] = useState(null);

  const [loading, _loading] = useState(true);

  useEffect(() => {
    _loading(true);
    (async () => {
      let {
        data: { result },
      } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}/uso_solo`);

      let series = [];
      let labels = [];
      result.forEach(u => {
        series.push(u.area_ha);
        labels.push(u.classe);
      }); //{ id: u.classe, label: u.classe, value: Math.round(u.area_ha * 100) / 100 }
      _series(series);

      _options({
        chart: {
          id: 'Uso_do_Solo',
        },
        colors: palette,
        labels,            
        tooltip: {
          y: {
            formatter: function(value) {
              return `${numeral(value).format('0,0')}ha`;
            }
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      });

      _loading(false);
    })();
  }, [selectedCAR]);

  return (
    <Card title="Uso do solo" loading={loading}>
      <div className="graph-container-2">
        {series && options && <div className="graph-container">{series && <Chart options={options} series={series} type="donut" height="300" />}</div>}
      </div>
    </Card>
  );
}

function Desmatamento({ selectedCAR }) {
  const [options, _options] = useState(null);
  const [series, _series] = useState(null);

  useEffect(() => {
    (async () => {
      let {
        data: { result },
      } = await axios.get(`${process.env.REACT_APP_SERVER}car/${selectedCAR}/desmatamento`);

      let categories = [],
        data = [];

      result.forEach(r => {
        categories.push(r.ano);
        data.push(r.area_ha);
      });

      // options
      _options({
        chart: {
          id: 'Desmatamento',
        },
        xaxis: {
          categories,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return numeral(value).format('0,0');
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: palette,
      });

      // series
      _series([
        {
          name: 'area_ha',
          data,
        },
      ]);
    })();
  }, [selectedCAR]);

  return (
    <Card title="Desmatamento">
      <div className="graph-container">{series && <Chart options={options} series={series} type="bar" height="300" />}</div>
    </Card>
  );
}
