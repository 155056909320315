import React from 'react';
import './styles.scss';
import RoundButton from '../RoundButton';

export default function NavBar({ sidebar, onSidebar }) {
  return (
    <>
      <div className="navbar">
        <div className="nav-brand">
          <img src="imgs/brand.png" alt="" />
        </div>
        <RoundButton sidebar={sidebar} onSidebar={onSidebar} />
      </div>
    </>
  );
}
