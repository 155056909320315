import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';

const BnlSlider = styled(Slider)(({ theme, disabled }) => ({
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: disabled ? '#ccc' : `3px solid ${theme.palette.primary.main}`,
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    top: -6,
    backgroundColor: 'unset',
    fontWeight: 'bold',
    color: disabled ? '#ccc' : theme.palette.primary.main,
    '&:before': {
      display: 'none',
    },
  },
}));

export default BnlSlider;