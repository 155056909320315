// react
import { useEffect, useRef } from 'react';

import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

export default function OLWMSLayer({ map, url, layers, opacity = 100 }) {
    let wmsRef = useRef();
  
    useEffect(() => {
      if (!map) return;
  
      // console.log('aqui', url, layers)
  
      wmsRef.current = new TileLayer({
        opacity: opacity / 100,
        source: new TileWMS({
          url,
          params: { layers, /* 'VERSION': '1.0.0', */ },
          serverType: 'geoserver',
          transition: 0,
        }),
      });
  
      map.addLayer(wmsRef.current);

      // const graphicUrl = wmsRef.current.getSource().getLegendUrl(/* resolution */);
      // console.log({ graphicUrl })       
  
      return () => {
        if (!map) return;
  
        if (wmsRef.current) map.removeLayer(wmsRef.current);
      };
    },[map, url, layers, opacity]);
  
    return null;
  }