import React, { useState, useEffect } from 'react';
import fastDeepEqual from 'fast-deep-equal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './styles.scss';
import { unstable_useId } from '@mui/material';

import axios from 'axios';

export function AutocompleteStyled({ textFieldProps, options, value, ...rest }) {
  const id = unstable_useId();

  return (
    <Autocomplete
      isOptionEqualToValue={fastDeepEqual}
      id={id}
      options={options || mockOptions}
      {...rest}
      renderInput={params => {
        !value && (params.inputProps.value = '');
        return <TextField {...textFieldProps} {...params} {...{ inputProps: { ...params.inputProps, ...textFieldProps.inputProps } }} />;
      }}
    />
  );
}

const mockOptions = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
];

export function AutocompleteAsyncStyled({ randomID, label, disabled, url, onChange, ...rest }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (disabled || !loading) {
      return undefined;
    }

    (async () => {
      let { data } = await axios.get(url);

      if (active) {
        setOptions(data);
      }
    })();

    return () => {
      active = false;
    };
  }, [disabled, loading, url]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <AutocompleteStyled
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      loading={loading}
      textFieldProps={{ label, inputProps: { autoComplete: randomID } }}
      onChange={(_, value) => onChange(null, value?.id)}
      {...rest}
    />
  );
}
