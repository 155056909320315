import React, { useState, useEffect } from 'react';
import './styles.scss';

import FloatingBoard from '../FloatingBoard';
import Card from '../Card';

import Chart from 'react-apexcharts';

import axios from 'axios';

import numeral from 'numeral';

import { palette } from '../../utils';

export default function Panel({ selectedCAR, body, onChange, generalInfo }) {
  return (
    <>
      <div className="panel">
        <div className="panel-header">
          <FloatingBoard selectedCAR={selectedCAR} body={body} onChange={onChange} generalInfo={generalInfo} />
        </div>

        <div className="panel-body">
          <Desmatamento />
          <Embargos />
          <Sobreposicoes />
          <Uso />
        </div>
      </div>
    </>
  );
}

function Desmatamento() {
  const [options, _options] = useState(null);
  const [series, _series] = useState(null);

  useEffect(() => {
    (async () => {
      let {
        data: { result },
      } = await axios.get(`${process.env.REACT_APP_SERVER}dash/desmatamento`);

      let categories = [],
        data = [];

      result.forEach(r => {
        categories.push(r.ano);
        data.push(r.area_ha);
      });

      // options
      _options({
        chart: {
          id: 'Desmatamento',
        },
        xaxis: {
          categories,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return numeral(value).format('0,0');
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: palette
      });

      // series
      _series([
        {
          name: 'area_ha',
          data,
        },
      ]);
    })();
  }, []);

  return (
    <Card title="Desmatamento">
      <div className="graph-container">{series && <Chart options={options} series={series} type="bar" height="300" />}</div>
    </Card>
  );
}

function Embargos() {
  const [options, _options] = useState(null);
  const [series, _series] = useState(null);

  useEffect(() => {
    (async () => {
      let {
        data: { result },
      } = await axios.get(`${process.env.REACT_APP_SERVER}dash/embargos`);

      let categories = [],
        data = [];

      result.forEach(r => {
        categories.push(r.data);
        data.push(r.area_ha);
      });

      // options
      _options({
        chart: {
          id: 'Embargos',
        },
        xaxis: {
          categories,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return numeral(value).format('0,0');
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: palette,
      });

      // series
      _series([
        {
          name: 'area_ha',
          data,
        },
      ]);
    })();
  }, []);

  return (
    <Card title="Embargos">
      <div className="graph-container">{series && <Chart options={options} series={series} type="bar" height="300" />}</div>
    </Card>
  );
}

function Sobreposicoes() {
  const [options, _options] = useState(null);
  const [series, _series] = useState(null);

  const [sobreposicao_ti, _sobreposicao_ti] = useState(null);

  useEffect(() => {
    (async () => {
      let {
        data: { result: result_sobre },
      } = await axios.get(`${process.env.REACT_APP_SERVER}dash/sobreposicao_ti`);
      _sobreposicao_ti(result_sobre);
      

      let {
        data: { result },
      } = await axios.get(`${process.env.REACT_APP_SERVER}dash/sobreposicao_ucs`);

      let categories = [],
        data = [];

      result.forEach(r => {
        categories.push(r.fonte);
        data.push(r.area_ha);
      });

      // options
      _options({
        chart: {
          id: 'Sobreposicoes',
        },
        xaxis: {
          categories,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return numeral(value).format('0,0');
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: palette,
      });

      // series
      _series([
        {
          name: 'area_ha',
          data,
        },
      ]);
    })();
  }, []);

  return (
    <Card title="Sobreposicão com terras indígenas">
      {sobreposicao_ti && (
        <>
          <div className="row">
            <div className="item">
              <div className="label">Total:</div>
              <div className="value">{sobreposicao_ti.count}</div>
            </div>
            <div className="item">
              <div className="label">Área:</div>
              <div className="value">{sobreposicao_ti.area_ha}</div>
            </div>
          </div>
          <div>
            <div className="graph-container">{series && <Chart options={options} series={series} type="bar" height="300" />}</div>
          </div>
        </>
      )}
    </Card>
  );
}

function Uso() {
  const [options, _options] = useState(null);
  const [series, _series] = useState(null);

  useEffect(() => {
    (async () => {
      let {
        data: { result },
      } = await axios.get(`${process.env.REACT_APP_SERVER}dash/uso_solo`);

      let categories = [],
        data = {
          apto: [],
          inapto: [],
        };

      result.forEach(r => {
        if(!categories.includes(r.classe)) categories.push(r.classe);
        data[r.protocolo].push(r.sum);
      });

      // options
      _options({
        chart: {
          id: 'Uso_do_Solo',
        },
        xaxis: {
          categories,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return numeral(value).format('0,0');
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: palette,
      });

      // series
      _series([
        {
          name: 'apto',
          data: data['apto'],
        },
        {
          name: 'inapto',
          data: data['inapto'],
        },
      ]);
    })();
  }, []);

  return (
    <Card title="Uso do Solo" bottom>
      <div className="graph-container">{series && <Chart options={options} series={series} type="bar" height="300" />}</div>
    </Card>
  );
}
